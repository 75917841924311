import { Auth0Context } from "@auth0/auth0-react";
import React from "react";
import { Card, Table } from "reactstrap";
import PropTypes from "prop-types";

import BankLog from "./bank_log";

class BankLogs extends React.Component {
  static contextType = Auth0Context;

  componentDidMount() {
    const domain = "cupcicdotdev.eu.auth0.com";
    this.context
      .getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "readwrite:all",
      })
      .then((token) => this.props.fetch_bank_logs(token));
  }

  render() {
    const logs = this.props.bank_logs.map((log) => (
      <BankLog key={log.tx_id} log={log} />
    ));

    return (
      <div style={{ width: "100%" }}>
        <h3 className="m-3">Bank logs</h3>
        <Card body>
          <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                <th>Account id</th>
                <th>ID</th>
                <th>Date</th>
                <th>What</th>
                <th>Amount</th>
                <th>Selected</th>
              </tr>
            </thead>
            <tbody>{logs}</tbody>
          </Table>
        </Card>
      </div>
    );
  }
}

BankLogs.propTypes = {
  ongoing_fetch_bank_logs: PropTypes.bool,
  fetch_bank_logs: PropTypes.func,
  bank_logs: PropTypes.array,
};

export default BankLogs;
