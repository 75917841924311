import React from 'react';

import { useAuth0 } from "@auth0/auth0-react";
import nemo from '../assets/nemo.png';

const Header = (props) => {
  const { isAuthenticated, user, logout } = useAuth0();
  let username = user?.name;
  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin
    });
  return (
    <div className="topnemo">
      <img src={nemo} alt="nemo" className="ml-4 mt-3" style={{ opacity: 0.85 }} />
      <div className="img-overlay">
        <div className="float-right mr-4 mt-3">
          {isAuthenticated &&
            <>
              <h6 className="text-primary">{username}</h6>
              <a href="#" onClick={() => logoutWithRedirect()} className="card-link">Logout</a>
            </>
          }
        </div>
      </div>
    </div>
  );
}
export default Header;
