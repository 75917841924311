import { combineReducers } from "redux";

import {
  FETCH_LAST_REVENUE,
  SUCCESS_LAST_REVENUE_FETCHED,
  FAILURE_LAST_REVENUE_FETCHED,
  FETCH_ALL_REVENUES,
  SUCCESS_ALL_REVENUES_FETCHED,
  FAILURE_ALL_REVENUES_FETCHED,
  COMPUTE_NEW_REVENUE,
  SUCCESS_NEW_REVENUE_COMPUTED,
  FAILURE_NEW_REVENUE_COMPUTED,
  FETCH_LAST_TRANSACTIONS,
  SUCCESS_LAST_TRANSACTIONS_FETCHED,
  FAILURE_LAST_TRANSACTIONS_FETCHED,
  FETCH_BANK_LOGS,
  SUCCESS_BANK_LOGS_FETCHED,
  FAILURE_BANK_LOGS_FETCHED,
} from "./actions";

function spin_fetch_last_revenue(state = false, action) {
  switch (action.type) {
    case FETCH_LAST_REVENUE:
      return true;
    case SUCCESS_LAST_REVENUE_FETCHED:
      return false;
    case FAILURE_LAST_REVENUE_FETCHED:
      return false;
    default:
      return state;
  }
}

function spin_compute_new_revenue(state = false, action) {
  switch (action.type) {
    case COMPUTE_NEW_REVENUE:
      return true;
    case SUCCESS_NEW_REVENUE_COMPUTED:
      return false;
    case FAILURE_NEW_REVENUE_COMPUTED:
      return false;
    default:
      return state;
  }
}

function spin_fetch_all_revenues(state = false, action) {
  switch (action.type) {
    case FETCH_ALL_REVENUES:
      return true;
    case SUCCESS_ALL_REVENUES_FETCHED:
      return false;
    case FAILURE_ALL_REVENUES_FETCHED:
      return false;
    default:
      return state;
  }
}

function spin_fetch_bank_logs(state = false, action) {
  switch (action.type) {
    case FETCH_BANK_LOGS:
      return true;
    case SUCCESS_BANK_LOGS_FETCHED:
      return false;
    case FAILURE_BANK_LOGS_FETCHED:
      return false;
    default:
      return state;
  }
}

function last_revenue(state = [], action) {
  switch (action.type) {
    case FETCH_LAST_REVENUE:
      return [];
    case SUCCESS_LAST_REVENUE_FETCHED:
      return action.revenue;
    case FAILURE_LAST_REVENUE_FETCHED:
      return [];
    case SUCCESS_NEW_REVENUE_COMPUTED:
      return action.revenue;
    case FAILURE_NEW_REVENUE_COMPUTED:
      return [];
    default:
      return state;
  }
}

function all_revenues(state = [], action) {
  switch (action.type) {
    case FETCH_ALL_REVENUES:
      return [];
    case SUCCESS_ALL_REVENUES_FETCHED:
      return action.revenues;
    case FAILURE_ALL_REVENUES_FETCHED:
      return [];
    default:
      return state;
  }
}

function bank_logs(state = [], action) {
  switch (action.type) {
    case FETCH_BANK_LOGS:
      return [];
    case SUCCESS_BANK_LOGS_FETCHED:
      return action.bank_logs;
    case FAILURE_BANK_LOGS_FETCHED:
      return [];
    default:
      return state;
  }
}

function current_account_ratio_id(state = "", action) {
  switch (action.type) {
    case FETCH_LAST_REVENUE:
      return "";
    case SUCCESS_LAST_REVENUE_FETCHED:
      return action.revenue[0].ratio_id;
    case FAILURE_LAST_REVENUE_FETCHED:
      return "";
    case SUCCESS_NEW_REVENUE_COMPUTED:
      return action.revenue[0].ratio_id;
    case FAILURE_NEW_REVENUE_COMPUTED:
      return "";
    default:
      return state;
  }
}

function current_account_revenue(state = [], action) {
  switch (action.type) {
    case FETCH_LAST_REVENUE:
      return "";
    case SUCCESS_LAST_REVENUE_FETCHED:
      return action.revenue;
    case FAILURE_LAST_REVENUE_FETCHED:
      return "";
    case SUCCESS_NEW_REVENUE_COMPUTED:
      return action.revenue;
    case FAILURE_NEW_REVENUE_COMPUTED:
      return "";
    default:
      return state;
  }
}

function current_account_transactions(state = [], action) {
  switch (action.type) {
    case FETCH_LAST_TRANSACTIONS:
      return "";
    case SUCCESS_LAST_TRANSACTIONS_FETCHED:
      return action.transactions;
    case FAILURE_LAST_TRANSACTIONS_FETCHED:
      return "";
    default:
      return state;
  }
}

const nemoApp = combineReducers({
  spin_fetch_last_revenue,
  spin_fetch_bank_logs,
  last_revenue,
  bank_logs,
  spin_fetch_all_revenues,
  all_revenues,
  spin_compute_new_revenue,
  current_account_ratio_id,
  current_account_revenue,
  current_account_transactions,
});

export default nemoApp;
