import { Auth0Context } from "@auth0/auth0-react";
import React from "react";
import { Col, Input, Row } from "reactstrap";
import { toast } from "react-toastify";
import check from "../assets/check.png";

class BankLog extends React.Component {
  static contextType = Auth0Context;

  constructor(props) {
    super(props);
    this.state = {
      status: props.log.selected,
      refreshing_status: false,
      refresh_ok: false,
    };
  }

  componentDidMount() {
    const domain = "cupcicdotdev.eu.auth0.com";
    this.context
      .getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "readwrite:all",
      })
      .then((token) => this.set_token(token));
  }

  set_token(token) {
    this.token = token;
  }

  onBanklogStatusRefresh(status) {
    this.setState({ refreshing_status: false });
    this.setState({ refresh_ok: true });
    setTimeout(() => {
      this.setState({ refresh_ok: false });
    }, 500);
    if (status == this.state.status) {
      toast.info(
        "the status is the same as before. It may be due to a parallel update made on the same banklog."
      );
    }
    this.setState({ status: status });
  }

  bank_log_refresh_status(e) {
    this.setState({ refreshing_status: true });

    fetch(`/banklogs/${this.props.log.tx_id}/select`, {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      method: "PUT",
    })
      .then((response) => response.json())
      .then((json) => this.onBanklogStatusRefresh(json))
      .catch((error) => {
        toast.error("Could not update status: " + error);
        this.setState({ refreshing_status: false });
      });
  }

  render() {
    return (
      <tr>
        <td>{this.props.log.account_id}</td>
        <td>{this.props.log.tx_id}</td>
        <td>{this.props.log.date}</td>
        <td>{this.props.log.what}</td>
        <td>{this.props.log.amount}</td>
        <td>
          <Row>
            <Col>
              <Input
                type="checkbox"
                disabled={this.state.refreshing_status}
                checked={this.state.status}
                className="form-check-input"
                onChange={(e) => this.bank_log_refresh_status(e)}
              />
            </Col>
            <Col>
              {this.state.refresh_ok && (
                <img
                  src={check}
                  alt="check"
                  className="ml-4 mt-3"
                  style={{ opacity: 1 }}
                />
              )}
            </Col>
          </Row>
        </td>
      </tr>
    );
  }
}

export default BankLog;
