import { connect } from 'react-redux'
import { FetchAllRevenues } from '../state/actions'
import HistoryCurrentAccount from '../components/history_current_account'


const mapStateToProps = state => {
    return {
        ongoing_fetch_all_revenues: state.spin_fetch_all_revenues,
        all_revenues: state.all_revenues
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        fetch_all_revenues: (token) => {
            dispatch(FetchAllRevenues(token))
        }
    })
}

const CHistoryCurrentAccount = connect(mapStateToProps, mapDispatchToProps)(HistoryCurrentAccount)

export default CHistoryCurrentAccount;
