import React from "react";

import { Auth0Context } from "@auth0/auth0-react";

import moment from "moment";
import PropTypes from "prop-types";

import CanvasJSReact from "../assets/canvasjs.react";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;

class Transactions extends React.Component {
  static contextType = Auth0Context;

  componentDidMount() {
    const domain = "cupcicdotdev.eu.auth0.com";
    this.context
      .getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "readwrite:all",
      })
      .then((token) => this.props.fetchLastTransactions(token));
  }

  constructor() {
    super();
  }

  month(revenue, month_to_end_date) {
    return moment(revenue.end_date)
      .subtract(month_to_end_date, "months")
      .format("MMM YY");
  }

  owners(revenue) {
    var owners = new Array();
    for (const r of revenue) {
      owners.push(r.owner);
    }
    return owners;
  }

  getDetails(transactions, owner, month) {
    var total = 0.0;
    var details = new Array();
    for (const t of transactions) {
      if (t.owner == owner && moment(t.date).format("MMM YY") == month) {
        details.push(t);
      }
    }
    return details;
  }

  tooltip(details) {
    return `<br/> ▪ <strong>${Math.round(details.amount)}€</strong> (${
      details.what
    })`;
  }

  point(owner, m) {
    const details = this.getDetails(
      this.props.transactions,
      owner,
      this.month(this.props.revenue[0], m)
    );
    const total = details.map((d) => d.amount).reduce((a, b) => a + b, 0);
    return {
      label: this.month(this.props.revenue[0], m),
      y: total,
      details: details.map((d) => this.tooltip(d)),
    };
  }

  get_data(owners) {
    var data = new Array();
    for (const owner of owners) {
      data.push({
        type: "stackedColumn",
        name: owner,
        showInLegend: true,
        yValueFormatString: "#,.'k€'",
        dataPoints: [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map((m) =>
          this.point(owner, m)
        ),
      });
    }
    return data;
  }

  render() {
    const options = {
      animationEnabled: true,
      axisY: {
        title: "in Eur",
        suffix: "€",
      },
      toolTip: {
        shared: true,
        content: "<span style='\"'color: {color};'\"'>{name}</span>: {details}",
      },
      colorSet: "simplex",
      legend: {
        verticalAlign: "center",
        horizontalAlign: "right",
        reversed: true,
        cursor: "pointer",
      },
      data: this.get_data(this.owners(this.props.revenue)),
    };
    return (
      <div class="col-12">
        <CanvasJSChart options={options} onRef={(ref) => (this.chart = ref)} />
        {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
      </div>
    );
  }
}

Transactions.propTypes = {
  ratio_id: PropTypes.string,
  revenue: PropTypes.array,
  transactions: PropTypes.array,
  fetchLastTransactions: PropTypes.func,
};

export default Transactions;
