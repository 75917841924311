import React from 'react';

import { useAuth0 } from "@auth0/auth0-react";
import { Button } from 'reactstrap';

const LoginButton = (props) => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  if (isAuthenticated) {
    return <Button color="primary" size="lg">Logout</Button>;
  }

  return (
    <Button
      id="qsLoginBtn"
      color="primary"
      onClick={() => loginWithRedirect({})}
    >
      Log in
    </Button>
  );
}
export default LoginButton;
