import { connect } from 'react-redux'
import { ComputeNewRevenue } from '../state/actions'
import NewCurrentAccount from '../components/new_current_account'


const mapStateToProps = state => {
    return {
        ongoing_compute_new_revenue: state.spin_compute_new_revenue,
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        compute_new_revenue: (token) => {
            dispatch(ComputeNewRevenue(token))
        }
    })
}

const CNewCurrentAccount = connect(mapStateToProps, mapDispatchToProps)(NewCurrentAccount)

export default CNewCurrentAccount;
