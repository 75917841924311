import React, { useState } from "react";
import {
  Badge,
  TabContent,
  TabPane,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Row,
  Container,
} from "reactstrap";
import classnames from "classnames";
import LatestSavings from "./last_savings";
import CLastCurrentAccount from "../containers/c_last_current_account";
import HistorySavings from "./history_savings";
import CHistoryCurrentAccount from "../containers/c_history_current_account";
import CBankLogs from "../containers/c_bank_logs";

const Tab = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <Navbar color="dark" dark horizontal={true} expand>
        <Nav navbar horizontal={true}>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Current accounts
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Bank logs
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggle("3");
              }}
            >
              Savings
            </NavLink>
          </NavItem>
        </Nav>
      </Navbar>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Container>
            <Row>
              <CLastCurrentAccount />
            </Row>
            <Row>
              <CHistoryCurrentAccount />
            </Row>
          </Container>
        </TabPane>
        <TabPane tabId="2">
          <Container>
            <Row>
              <CBankLogs />
            </Row>
          </Container>
        </TabPane>
        <TabPane tabId="3">
          <Container>
            <Row>
              <LatestSavings />
            </Row>
            <Row>
              <HistorySavings />
            </Row>
          </Container>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Tab;
