import React from 'react';

import Tab from './tab';
import LoginButton from './login_button';
import Header from './header';
import { useAuth0 } from "@auth0/auth0-react";

import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = (props) => {
  const { isAuthenticated } = useAuth0();

  const mainTab = isAuthenticated ? <Tab /> : <LoginButton />;
  return (
    <React.Fragment>
      <ToastContainer hideProgressBar={true} />
      <div className="container" style={{ width: 1024 + 'px' }}>
        <div className="row">
          <div className="col-lg-12">
            <Header />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            {mainTab}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default App;
