import React from "react";
import { Card, CardText } from "reactstrap";

const LastSavings = (props) => {
  return (
    <div style={{ width: "100%" }}>
      <h3 className="m-3">Last report</h3>
      <Card body>
        <CardText>This month,</CardText>
        <CardText>
          **Claire** earned **0**€. **Claire** has to transfer **0**€ (**5%**)
          on the common saving account.
        </CardText>
        <CardText>
          **Manu** earned **0**€. **Manu** has to transfer **0**€ (**5%**) on
          the common saving account.
        </CardText>
      </Card>
    </div>
  );
};

export default LastSavings;
