import React from "react";
import { Auth0Context } from "@auth0/auth0-react";
import { Card, CardBody, CardText } from "reactstrap";
import PropTypes from "prop-types";

class NewCurrentAccount extends React.Component {
  static contextType = Auth0Context;

  constructor(props) {
    super(props);
    this.state = {
      token: null,
    };
  }

  componentDidMount() {
    const domain = "cupcicdotdev.eu.auth0.com";
    this.context
      .getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "readwrite:all",
      })
      .then((token) => this.setState({ token: token }));
  }

  render() {
    return (
      <Card>
        <CardBody>
          <button
            class="btn btn-primary"
            type="button"
            disabled={this.props.ongoing_compute_new_revenue}
            onClick={() => this.props.compute_new_revenue(this.state.token)}
          >
            {this.props.ongoing_compute_new_revenue && (
              <span
                class="spinner-border spinner-border-sm mr-1"
                role="status"
                aria-hidden="true"
              />
            )}
            Compute new ratio
          </button>
          <CardText className="mt-2">
            This will compute the total revenues based on one year revenues up
            to the first day of the current month.
          </CardText>
        </CardBody>
      </Card>
    );
  }
}

NewCurrentAccount.propTypes = {
  ongoing_compute_new_revenue: PropTypes.bool,
  compute_new_revenue: PropTypes.func,
};

export default NewCurrentAccount;
