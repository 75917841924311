import { connect } from 'react-redux'
import { FetchTransactionsLastRevenue } from '../state/actions'

import Transactions from '../components/transactions'




const mapStateToProps = state => {
    return {
        ratio_id: state.current_account_ratio_id,
        revenue: state.current_account_revenue,
        transactions: state.current_account_transactions,
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        fetchLastTransactions: (token) => {
            dispatch(FetchTransactionsLastRevenue(token))
        }
    })
}

const CTransactions = connect(mapStateToProps, mapDispatchToProps)(Transactions)

export default CTransactions;
