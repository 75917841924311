import { connect } from 'react-redux'
import { FetchLastRevenue } from '../state/actions'
import LastCurrentAccount from '../components/last_current_account'


const mapStateToProps = state => {
    return {
        ongoing_fetch_last_revenue: state.spin_fetch_last_revenue,
        last_revenue: state.last_revenue
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        fetch_last_revenue: (token) => {
            dispatch(FetchLastRevenue(token))
        }
    })
}

const CLastCurrentAccount = connect(mapStateToProps, mapDispatchToProps)(LastCurrentAccount)

export default CLastCurrentAccount;
