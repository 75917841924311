import { connect } from "react-redux";
import { FetchBankLogs } from "../state/actions";
import BankLogs from "../components/bank_logs";

const mapStateToProps = (state) => {
  return {
    ongoing_fetch_bank_logs: state.spin_fetch_bank_logs,
    bank_logs: state.bank_logs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetch_bank_logs: (token) => {
      dispatch(FetchBankLogs(token));
    },
  };
};

const CBankLogs = connect(mapStateToProps, mapDispatchToProps)(BankLogs);

export default CBankLogs;
