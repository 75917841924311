import React from "react";
import { Table } from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";
import CNewCurrentAccount from "../containers/c_new_current_account";

import CanvasJSReact from "../assets/canvasjs.react";
import CTransactions from "../containers/c_transactions";
import { Auth0Context } from "@auth0/auth0-react";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;

CanvasJS.addColorSet("simplex", ["#9B479F", "#D9831F", "#007bff", "#469408"]);

class LastCurrentAccount extends React.Component {
  static contextType = Auth0Context;

  componentDidMount() {
    const domain = "cupcicdotdev.eu.auth0.com";
    this.context
      .getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "readwrite:all",
      })
      .then((token) => this.props.fetch_last_revenue(token));
  }

  point(r) {
    return { name: r.owner, y: r.revenue };
  }

  render() {
    const info_compute_datetime = this.props.last_revenue[0]
      ? moment(this.props.last_revenue[0].compute_datetime).format(
          "dddd, MMMM Do YYYY[ at ] h:mm:ss a"
        )
      : "void";
    const info_ratio_id = this.props.last_revenue[0]
      ? this.props.last_revenue[0].ratio_id
      : "void";
    const info_end_date = this.props.last_revenue[0]
      ? moment(this.props.last_revenue[0].end_date).format("dddd, MMMM Do YYYY")
      : "void";
    const ratios = this.props.last_revenue.map((r) => this.point(r));
    const options = {
      animationEnabled: true,
      subtitles: [
        {
          text: "❤️",
          verticalAlign: "center",
          fontSize: 32,
          dockInsidePlotArea: true,
        },
        {
          text: "One year up to " + info_end_date,
          verticalAlign: "top",
          fontSize: 12,
          fontFamily: "Open Sans",
        },
        {
          text: `ratio_id: ${info_ratio_id} (computed on ${info_compute_datetime})`,
          verticalAlign: "bottom",
          horizontalAlign: "right",
          fontStyle: "italic",
          fontSize: 11,
          padding: 5,
          fontFamily: "Open Sans",
        },
      ],
      colorSet: "simplex",
      data: [
        {
          type: "doughnut",
          showInLegend: true,
          indexLabel: "{name}: #percent%",
          indexTooltip: "{name}: {y}k€",
          yValueFormatString: "#,.'k€'",
          dataPoints: ratios,
        },
      ],
    };

    return (
      <div style={{ width: "100%" }}>
        <h3 className="m-3">Latest report</h3>
        <h5 className="m-3">Revenue and ratio</h5>
        <div class="row">
          <div class="col-sm-9">
            <CanvasJSChart options={options} />
          </div>
          <div class="col-sm-3">
            <CNewCurrentAccount />
          </div>
        </div>
        <div class="row">
          <CTransactions />
        </div>
        <h5 className="m-3">Indicative transfers</h5>
        <h6 class="text-muted m-3">
          Please transfer this amount to the joint account
        </h6>
        <Table striped bordered hover size="lg" responsive>
          <thead>
            <tr>
              <th>Total</th>
              {this.props.last_revenue.map((r) => (
                <th>{r.owner}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[1000, 2000, 4000].map((howmuch) => (
              <tr>
                <th>{howmuch}</th>
                {this.props.last_revenue.map((r) => (
                  <td>{Math.floor(howmuch * r.ratio + 0.5)}€</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

LastCurrentAccount.propTypes = {
  ongoing_fetch_last_revenue: PropTypes.bool,
  fetch_last_revenue: PropTypes.func,
  last_revenue: PropTypes.array,
};

export default LastCurrentAccount;
