import fetch from "cross-fetch";

import { toast } from "react-toastify";

/*
 * action types
 */

export const SEND_NEW_TRANSACTION = "SEND_NEW_TRANSACTION";
export const SUCCESS_NEW_TRANSACTION = "SUCCESS_NEW_TRANSACTION";
export const FAILURE_NEW_TRANSACTION = "FAILURE_NEW_TRANSACTION";

export const FETCH_LAST_REVENUE = "FETCH_LAST_REVENUE";
export const SUCCESS_LAST_REVENUE_FETCHED = "SUCCESS_LAST_REVENUE_FETCHED";
export const FAILURE_LAST_REVENUE_FETCHED = "FAILURE_LAST_REVENUE_FETCHED";

export const FETCH_ALL_REVENUES = "FETCH_ALL_REVENUES";
export const SUCCESS_ALL_REVENUES_FETCHED = "SUCCESS_ALL_REVENUES_FETCHED";
export const FAILURE_ALL_REVENUES_FETCHED = "FAILURE_ALL_REVENUES_FETCHED";

export const COMPUTE_NEW_REVENUE = "COMPUTE_NEW_REVENUE";
export const SUCCESS_NEW_REVENUE_COMPUTED = "SUCCESS_NEW_REVENUE_COMPUTED";
export const FAILURE_NEW_REVENUE_COMPUTED = "FAILURE_NEW_REVENUE_COMPUTED";

export const FETCH_LAST_TRANSACTIONS = "FETCH_LAST_TRANSACTIONS";
export const SUCCESS_LAST_TRANSACTIONS_FETCHED =
  "SUCCESS_LAST_TRANSACTIONS_FETCHED";
export const FAILURE_LAST_TRANSACTIONS_FETCHED =
  "FAILURE_LAST_TRANSACTIONS_FETCHED";

export const FETCH_BANK_LOGS = "FETCH_BANK_LOGS";
export const SUCCESS_BANK_LOGS_FETCHED = "SUCCESS_BANK_LOGS_FETCHED";
export const FAILURE_BANK_LOGS_FETCHED = "FAILURE_BANK_LOGS_FETCHED";

/*
 * action creators
 */

export function FetchLastRevenue(token) {
  return function (dispatch) {
    dispatch({ type: FETCH_LAST_REVENUE });

    fetch("/revenues/last", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) =>
        dispatch({ type: SUCCESS_LAST_REVENUE_FETCHED, revenue: json })
      )
      .catch((error) => {
        toast("Could not fetch last revenue: " + error);
        dispatch({ type: FAILURE_LAST_REVENUE_FETCHED, error: error });
      });
  };
}

export function FetchAllRevenues(token) {
  return function (dispatch) {
    dispatch({ type: FETCH_ALL_REVENUES });

    fetch("/revenues/all", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) =>
        dispatch({ type: SUCCESS_ALL_REVENUES_FETCHED, revenues: json })
      )
      .catch((error) => {
        toast.error("Could not fetch all revenues: " + error);
        dispatch({ type: FAILURE_ALL_REVENUES_FETCHED, error: error });
      });
  };
}

export function FetchBankLogs(token) {
  return function (dispatch) {
    dispatch({ type: FETCH_BANK_LOGS });

    // let dummy_result = [
    //   {
    //     tx_id: "1234",
    //     date: "2012-03-02",
    //     issued_by: "SuperBoulot",
    //     what: "Salaire",
    //     amount: "100",
    //     selected: true,
    //   },
    // ];

    // dispatch({ type: SUCCESS_BANK_LOGS_FETCHED, bank_logs: dummy_result });

    fetch("/banklogs", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) =>
        dispatch({ type: SUCCESS_BANK_LOGS_FETCHED, bank_logs: json })
      )
      .catch((error) => {
        toast.error("Could not fetch bank logs: " + error);
        dispatch({ type: FAILURE_BANK_LOGS_FETCHED, error: error });
      });
  };
}

export function ComputeNewRevenue(token) {
  var firstOfTheMonth = new Date();
  firstOfTheMonth.setDate(1);

  return function (dispatch) {
    dispatch({ type: COMPUTE_NEW_REVENUE });

    fetch("/revenues", {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
      body: JSON.stringify({
        end_date: firstOfTheMonth.toISOString().slice(0, 10),
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: SUCCESS_NEW_REVENUE_COMPUTED, revenue: json });
        dispatch(FetchAllRevenues(token));
        dispatch(FetchTransactionsLastRevenue(token));
      })
      .catch((error) => {
        toast.error("Could not compute new revenue: " + error);
        dispatch({ type: FAILURE_NEW_REVENUE_COMPUTED, error: error });
      });
  };
}

export function FetchTransactionsLastRevenue(token) {
  return function (dispatch) {
    dispatch({ type: FETCH_LAST_TRANSACTIONS });
    fetch("/transactions/last", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) =>
        dispatch({
          type: SUCCESS_LAST_TRANSACTIONS_FETCHED,
          transactions: json,
        })
      )
      .catch((error) => {
        toast("Could not fetch last transactions: " + error);
        dispatch({ type: FAILURE_LAST_TRANSACTIONS_FETCHED, error: error });
      });
  };
}
