import React, { useState } from "react";
import { Card, Table } from "reactstrap";

const HistorySavings = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div style={{ width: "100%" }}>
      <h3 className="m-3">History</h3>
      <Card body>
        <Table striped bordered hover size="sm" responsive>
          <thead>
            <tr>
              <th>Month</th>
              <th>Owner</th>
              <th>Earning</th>
              <th>Transfer to savings</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>01-2020</td>
              <td>Claire</td>
              <td>1500</td>
              <td>75</td>
            </tr>
            <tr>
              <td>01-2020</td>
              <td>Manu</td>
              <td>200</td>
              <td>10</td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </div>
  );
};

export default HistorySavings;
