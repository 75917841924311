import { Auth0Context } from "@auth0/auth0-react";
import React from "react";
import { Card, Table } from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";

class HistoryCurrentAccount extends React.Component {
  static contextType = Auth0Context;

  componentDidMount() {
    const domain = "cupcicdotdev.eu.auth0.com";
    this.context
      .getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "readwrite:all",
      })
      .then((token) => this.props.fetch_all_revenues(token));
  }

  render() {
    const revenues = this.props.all_revenues.map((revenue) => (
      <tr>
        <td>{revenue.ratio_id}</td>
        <td>{revenue.end_date}</td>
        <td>{revenue.owner}</td>
        <td>{Math.floor(revenue.revenue / 100 + 0.5) * 100}€</td>
        <td>{Math.floor(revenue.ratio * 100 + 0.5)}%</td>
        <td>{moment(revenue.compute_datetime).fromNow()}</td>
      </tr>
    ));

    return (
      <div style={{ width: "100%" }}>
        <h3 className="m-3">History</h3>
        <Card body>
          <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>One year up to </th>
                <th>Owner</th>
                <th>Earning</th>
                <th>Earning ratio</th>
                <th>Computed</th>
              </tr>
            </thead>
            <tbody>{revenues}</tbody>
          </Table>
        </Card>
      </div>
    );
  }
}

HistoryCurrentAccount.propTypes = {
  ongoing_fetch_all_revenues: PropTypes.bool,
  fetch_all_revenues: PropTypes.func,
  all_revenues: PropTypes.array,
};

export default HistoryCurrentAccount;
